<template>
  <div class="wrapper">
    
  </div>
</template>

<script>
import { orderPay, orderPayH5 } from '../../api'
export default {
  components:{},
  props:{},
  data(){
    return {
    }
  },
  watch:{},
  computed:{},
  methods:{
    orderPay(orderSn) {
      orderPay(orderSn).then((res)=>{
        this.$toast.success({
              message: '支付成功',
              duration: 1500,
              forbidClick: true,
        })
        setTimeout(() => {
            this.$router.replace({ path: '/my/orderListSMCS' })
        }, 1500)
      }).catch(()=>{
          this.$toast.clear()
          this.$router.replace({ path: '/my/orderListSMCS', query: { type: 0 } })
      })
    },
    orderPayH5(orderNo) {
      orderPayH5({
        orderPaySn: orderNo
      }).then((res)=>{
        this.$toast.success({
              message: '支付成功',
              duration: 1500,
              forbidClick: true,
        })
        setTimeout(() => {
            this.$router.replace({ path: '/my/orderListSMCS' })
        }, 1500)
      }).catch(()=>{
          this.$toast.clear()
          this.$router.replace({ path: '/my/orderListSMCS', query: { type: 0 } })
      })
    },
    // 获取连接参数正则
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    }
  },
  created(){
    this.$toast.loading({
        forbidClick: true,
        duration: 0
    })
    if (this.getQueryString('mchntOrderNo')||this.$route.query.mchntOrderNo) {
        console.log(this.getQueryString('mchntOrderNo')||this.$route.query.mchntOrderNo);
      this.orderPay(this.getQueryString('mchntOrderNo')||this.$route.query.mchntOrderNo)
    } else if (this.getQueryString('orderNo')||this.$route.query.orderNo) {
      this.orderPayH5(this.getQueryString('orderNo')||this.$route.query.orderNo)
    }
  },
  mounted(){
   
  }
}
</script>
<style scoped>

</style>